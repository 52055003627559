"use client"

import { useOnClickOutside } from "@/hooks/useOnClickOutside"
import { useWindowResize } from "@/hooks/useWindowResize"
import LanguageIcon from "@/layout/icons/LanguageIcon"
import { useConfigStore } from "@/store/config-store"
import { useHeader } from "@/store/header-store"
import { urlParser } from "@lib/urlParser"
import { ClinicConfigStoryblok, ConfigStoryblok, MenuLinkStoryblok } from "@storyblok-types"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"
import Link from "next/link"
import React, { useEffect, useRef, useState } from "react"
import PrimaryItem from "./PrimaryItem"

type NavigationProps = {
  items: MenuLinkStoryblok[] | undefined
  footer_privacy_menu: ConfigStoryblok["footer_privacy_menu"] | ClinicConfigStoryblok["footer_privacy_menu"]
  // menuStatus: boolean
  enableHover: boolean
  enableFocus: boolean
  onClose: () => void
}

const Navigation = ({ items, footer_privacy_menu, enableHover, enableFocus, onClose }: NavigationProps) => {
  const [activeItem, setActiveItem] = useState<string | null>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const threshold = 1024
  const [initialWidth, setInitialWidth] = useState<number | null>(null)
  const { isOpen } = useHeader()
  // const pathname = usePathname();
  const isEnglish = useConfigStore.getState().en

  let languageLink = {
    url: "/en",
    label: "English"
  }

  if (isEnglish) {
    languageLink = {
      url: "/",
      label: "Svenska"
    }
  }

  const handleWindowResize = () => {
    if (typeof window !== "undefined") {
      const windowWidth = window.innerWidth

      if (initialWidth === null || initialWidth !== windowWidth) {
        if (initialWidth !== null) {
          if (initialWidth <= threshold && windowWidth > threshold) {
            setActiveItem(null)
          } else if (initialWidth > threshold && windowWidth <= threshold) {
            setActiveItem(null)
          }
        }

        setInitialWidth(windowWidth)
      }
    }
  }

  useWindowResize(handleWindowResize)

  useEffect(() => {
    if (typeof window !== "undefined" && initialWidth === null) {
      setInitialWidth(window.innerWidth)
    }
  }, [initialWidth])

  const handleOutsideInteraction = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
    if (event instanceof KeyboardEvent && event.key === "Escape") {
      setActiveItem(null)
      onClose()
    }
  }

  useOnClickOutside(menuRef, handleOutsideInteraction)

  const toggleActiveItem = (_uid: string) => {
    if (activeItem === _uid) {
      setActiveItem(null)
    } else {
      setActiveItem(_uid)
    }
  }

  const toggleKeyboardOpenItem = (_uid: string) => setActiveItem(_uid)
  const handleMouseLeave = () => setActiveItem(null)

  return (
    <nav
      ref={menuRef}
      onMouseLeave={enableHover ? handleMouseLeave : undefined}
      className={cn(
        "z-40 xl:border-y-4 xl:border-transparent",
        "max-xl:fixed max-xl:right-0 max-xl:top-0 max-xl:min-h-screen max-xl:w-full max-xl:max-w-[480px] max-xl:translate-x-full max-xl:bg-background max-xl:transition-transform",
        isOpen && "max-xl:translate-x-0 max-xl:drop-shadow-xl"
      )}
    >
      <div className="max-xl:flex max-xl:h-full max-xl:max-h-screen max-xl:min-h-screen max-xl:flex-col max-xl:justify-between max-xl:overflow-auto max-xl:px-8 max-xl:pt-20">
        <ul className="flex flex-wrap items-center">
          {items?.map((item, idx) => {
            const hasChildren = item?.sub_items && item?.sub_items.length > 0

            return (
              <React.Fragment key={idx}>
                {hasChildren ? (
                  <PrimaryItem
                    item={item}
                    isOpen={activeItem === item._uid}
                    toggleOpen={toggleActiveItem}
                    toggleKeyboardOpen={toggleKeyboardOpenItem}
                    enableHoverAndKeyboard={enableHover}
                    enableFocus={enableFocus}
                    onClose={onClose}
                    className="border-1 relative w-full border-neutral-30 xl:w-auto xl:px-2 xl:first:pl-0 xl:last:pr-0"
                  />
                ) : (
                  <li
                    className="border-1 relative w-full border-neutral-30 xl:w-auto xl:px-2 xl:pb-6 xl:pt-6 xl:first:pl-0 xl:last:pr-0"
                    onMouseEnter={() => (enableHover ? setActiveItem(null) : undefined)}
                  >
                    <Link
                      onClick={onClose}
                      href={urlParser(item.link.cached_url)}
                      className="outline-none transition-colors max-xl:block max-xl:rounded-full max-xl:px-8 max-xl:py-4 max-xl:focus-within:bg-surface max-xl:hover:bg-surface xl:focus-within:text-primary-50 xl:hover:text-primary-50"
                    >
                      <Paragraph size="small" className="max-xl:font-display max-xl:text-lg max-xl:font-medium" asChild>
                        <span>{item.name}</span>
                      </Paragraph>
                    </Link>
                  </li>
                )}
              </React.Fragment>
            )
          })}
        </ul>

        <div className="block py-20 xl:hidden">
          <Link
            onClick={onClose}
            href={languageLink.url}
            className="outline-none transition-colors max-xl:block max-xl:rounded-full max-xl:px-8 max-xl:py-2 max-xl:focus-within:text-primary-50 max-xl:hover:text-primary-50"
          >
            <Paragraph size="small" asChild>
              <span className="inline-flex items-center">
                {languageLink.label}
                <LanguageIcon />
              </span>
            </Paragraph>
          </Link>

          {footer_privacy_menu?.map((item, idx) => (
            <Link
              onClick={onClose}
              href={urlParser(item.link.cached_url)}
              key={idx}
              className="outline-none transition-colors max-xl:block max-xl:rounded-full max-xl:px-8 max-xl:py-2 max-xl:focus-within:text-primary-50 max-xl:hover:text-primary-50"
            >
              <Paragraph size="small" asChild>
                <span>{item.name}</span>
              </Paragraph>
            </Link>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default Navigation
