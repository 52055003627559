"use client"

import ChevronDownIcon from "@layout/icons/ChevronDownIcon"
import { urlParser } from "@lib/urlParser"
import { MenuLinkStoryblok } from "@storyblok-types"
import { Paragraph } from "@ui-library/typography/Body"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"
import Link from "next/link"

type PrimaryItemProps = {
  item: MenuLinkStoryblok
  isOpen: boolean
  toggleOpen: (_uid: string) => void
  toggleKeyboardOpen: (_uid: string) => void
  enableHoverAndKeyboard: boolean
  enableFocus: boolean
  className?: string
  onClose: () => void
}

const PrimaryItem = ({
  item,
  isOpen,
  toggleOpen,
  toggleKeyboardOpen,
  enableHoverAndKeyboard,
  enableFocus,
  className,
  onClose
}: PrimaryItemProps) => {
  const handleToggle = () => {
    toggleOpen(item._uid)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleKeyboardOpen(item._uid)
    }
    if (e.key === "Esc") {
      toggleOpen("")
    }
  }

  const handleFocusWithin = () => {
    if (!enableFocus) return
    toggleOpen(item._uid)
  }

  const subItemsCount = item.sub_items?.length ? item.sub_items?.length : 0

  return (
    <li className={className}>
      <button
        onClick={handleToggle}
        onKeyDown={enableHoverAndKeyboard ? handleKeyPress : undefined}
        onMouseEnter={enableHoverAndKeyboard ? handleToggle : undefined}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-controls={item._uid}
        onFocus={enableFocus ? handleFocusWithin : undefined}
        onBlur={enableFocus ? handleToggle : undefined}
        className={cn(
          "group flex items-center transition-colors max-xl:py-3 xl:relative xl:justify-center xl:pb-6 xl:pt-6",
          "w-full justify-between outline-none max-xl:rounded-full max-xl:px-8 max-xl:hover:bg-surface",
          isOpen && "max-xl:bg-surface xl:text-primary-50"
        )}
      >
        <Paragraph size="small" className="mr-1 max-xl:font-display max-xl:text-lg max-xl:font-medium" asChild>
          <span>{item.name}</span>
        </Paragraph>
        <ChevronDownIcon width={20} className={cn("transition-transform", isOpen && "-rotate-180")} />
        <i
          className={cn(
            "pointer-events-none absolute bottom-2 left-0 block h-1 w-full bg-primary-50 opacity-0 group-focus:opacity-100 max-xl:hidden",
            isOpen && "opacity-100"
          )}
        />
      </button>
      <div
        id={item._uid}
        aria-hidden={!isOpen}
        role="menu"
        className={cn(
          "pt-4 duration-300 max-xl:pb-8 xl:absolute xl:left-2/4 xl:top-full xl:min-w-[520px] xl:-translate-x-2/4 xl:transition-[opacity,_transform]",
          subItemsCount > 2 && "xl:min-w-[640px]",
          !isOpen && "hidden xl:pointer-events-none xl:block xl:translate-y-6 xl:opacity-0",
          isOpen && "xl:pointer-events-auto xl:translate-y-0 xl:opacity-100"
        )}
      >
        <div
          className={cn(
            "relative w-full items-stretch xl:flex xl:flex-wrap xl:rounded-lg xl:bg-surface xl:p-2 xl:drop-shadow-lg"
          )}
        >
          <span className="hidden xl:absolute xl:-top-1 xl:left-2/4 xl:block xl:h-4 xl:w-4 xl:rotate-45 xl:bg-surface" />
          {item.sub_items?.map((subItem, idx) => (
            <div
              key={idx}
              className={cn("h-auto w-full max-xl:mb-4 max-xl:last:mb-0 xl:p-2", subItemsCount > 2 && "xl:w-2/4")}
            >
              <Link
                onClick={onClose}
                onFocus={enableHoverAndKeyboard ? handleFocusWithin : undefined}
                onBlur={enableHoverAndKeyboard ? handleFocusWithin : undefined}
                href={urlParser(subItem.link.cached_url)}
                className="group block h-full outline-none transition-colors max-xl:px-8 xl:rounded-lg xl:p-4 xl:hover:bg-background xl:focus:bg-background"
              >
                <span className="text-on-neutral-10">
                  <Label
                    size="small"
                    className="block cursor-pointer text-on-background transition-colors max-xl:text-base max-xl:font-normal max-xl:group-hover:text-primary-50"
                  >
                    {subItem.name}
                  </Label>
                  <span className="max-xl:hidden">{subItem.description}</span>
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </li>
  )
}

export default PrimaryItem
